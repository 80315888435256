import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'husky-app-shared-ui-skeleton-block',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div
      class="animate-pulse rounded bg-gray-100"
      [ngStyle]="{
        width: width(),
        height: height(),
        'border-radius': borderRadius(),
      }"
      [class.mt-2]="margin() === 'normal'"
      [class.mt-4]="margin() === 'large'"
      [class.flex-shrink-0]="flexShrink()"></div>
  `,
})
export class AppSharedUiSkeletonBlockComponent {
  width = input<string>('100%');
  height = input<string>('4rem');
  borderRadius = input<string>('0.375rem');
  margin = input<'none' | 'normal' | 'large'>('normal');
  flexShrink = input<boolean>(false);
}
