import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'husky-app-shared-ui-skeleton',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div
      [class]="'overflow-hidden ' + (className() || '')"
      [ngStyle]="{ 'min-height': minHeight() }">
      <ng-content></ng-content>
    </div>
  `,
})
export class AppSharedUiSkeletonComponent {
  className = input<string>('');
  minHeight = input<string>('auto');
}
