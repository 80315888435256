import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'husky-app-shared-ui-skeleton-circle',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div
      class="animate-pulse rounded-full bg-gray-100"
      [ngStyle]="{
        width: size(),
        height: size(),
      }"
      [class.mt-2]="margin() === 'normal'"
      [class.mt-4]="margin() === 'large'"
      [class.flex-shrink-0]="flexShrink()"></div>
  `,
})
export class AppSharedUiSkeletonCircleComponent {
  size = input<string>('3rem');
  margin = input<'none' | 'normal' | 'large'>('normal');
  flexShrink = input<boolean>(false);
}
