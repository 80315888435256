@use '../../../../../../../node_modules/@angular/cdk/index' as cdk;
@use '../../../../../../../node_modules/@angular/material/index' as mat;

.husky-drawer-container {
  @apply block w-full max-w-full bg-white text-black outline-0;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  transform: translateX(100%);

  @include cdk.high-contrast(active, off) {
    outline: 1px solid;
  }
}

.husky-drawer-content-wrapper {
  @apply box-border h-full max-w-full overflow-auto overscroll-y-contain;
}

.husky-drawer--closing {
  transform: translateX(100%);
}

.husky-drawer--opening {
  transform: translateX(0);
}

.husky-drawer--open {
  transform: translateX(0);
}
