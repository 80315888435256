import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
  selector: 'husky-app-shared-ui-skeleton-line',
  standalone: true,
  imports: [CommonModule],
  template: `
    <div
      class="animate-pulse rounded bg-gray-100"
      [class.mt-2]="margin() === 'normal'"
      [class.mt-4]="margin() === 'large'"
      [ngClass]="getWidthClass()"
      [ngStyle]="{
        height: height(),
        width: customWidth(),
        'border-radius': borderRadius(),
      }"></div>
  `,
})
export class AppSharedUiSkeletonLineComponent {
  width = input<'full' | '3/4' | '1/2' | '1/4' | 'custom'>('full');
  customWidth = input<string | null>(null);
  height = input<string>('1rem');
  margin = input<'none' | 'normal' | 'large'>('normal');
  borderRadius = input<string>('0.25rem');

  getWidthClass(): string {
    if (this.width() === 'custom' && this.customWidth()) {
      return '';
    }

    switch (this.width()) {
      case 'full':
        return 'w-full';
      case '3/4':
        return 'w-3/4';
      case '1/2':
        return 'w-1/2';
      case '1/4':
        return 'w-1/4';
      default:
        return 'w-full';
    }
  }
}
